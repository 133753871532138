import React, { useState } from 'react';
import { Upload, Loader2, Lock } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import useCredits from '../hooks/useCredits';
import { uploadAudio, separateAudio, type SeparatedAudioResult } from '../services/assemblyAI';
import FileUpload from '../components/FileUpload';
import AudioSeparationResult from '../components/AudioSeparationResult';
import Logo from '../components/Logo';
import AuroraBackground from '../components/AuroraBackground';
import TextGenerateEffect from '../components/TextGenerateEffect';

const HomePage = () => {
  const { user } = useAuth();
  const { credits, useCredits: spendCredits } = useCredits();
  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [separationResult, setSeparationResult] = useState<SeparatedAudioResult | null>(null);

  const handleFileChange = (fileList: FileList | null) => {
    if (!user) {
      setError('Please log in or register to upload audio files');
      return;
    }

    if (!user.emailVerified) {
      setError('Please verify your email before processing audio files');
      return;
    }

    if (credits < 2) {
      setError('Insufficient credits. You need 2 credits to process an audio file');
      return;
    }

    if (fileList && fileList.length > 0) {
      setFiles(Array.from(fileList));
      setError('');
      setSeparationResult(null);
    }
  };

  const handleUpload = async () => {
    if (!user) {
      setError('Please log in or register to process audio files');
      return;
    }

    if (!user.emailVerified) {
      setError('Please verify your email before processing audio files');
      return;
    }

    if (credits < 2) {
      setError('Insufficient credits. You need 2 credits to process an audio file');
      return;
    }

    if (files.length === 0) {
      setError('Please select a file first');
      return;
    }

    try {
      setIsUploading(true);
      setError('');
      
      const creditResult = await spendCredits(2);
      if (!creditResult.success) {
        throw new Error(creditResult.error || 'Failed to process credits');
      }
      
      const audioUrl = await uploadAudio(files[0]);
      setIsProcessing(true);
      const result = await separateAudio(audioUrl);
      setSeparationResult(result);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsUploading(false);
      setIsProcessing(false);
    }
  };

  return (
    <AuroraBackground className="min-h-screen p-6">
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-8">
        <div className="flex flex-col items-center mb-8">
          <div className="w-48 h-48 mb-4">
            <Logo />
          </div>
          <h1 className="text-4xl font-bold text-gray-800 text-center mb-4">
            SpeakerSplit
          </h1>
          <TextGenerateEffect
            words="Transcribe Audio into Text and Separate dialogue from any audio track using AI"
            className="text-gray-600 text-center text-lg mb-4"
          />
        </div>

        {!user ? (
          <div className="text-center p-8 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
            <Lock className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Authentication Required
            </h2>
            <p className="text-gray-600 mb-4">
              Please log in or register to upload and process audio files
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="#/login"
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
              >
                Log In
              </a>
              <a
                href="#/register"
                className="px-4 py-2 bg-white text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50 transition-colors"
              >
                Register
              </a>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            {credits < 2 && (
              <div className="p-4 bg-amber-50 border border-amber-200 rounded-lg text-amber-700 mb-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="font-semibold">Low Balance Alert</h3>
                    <p>You need 2 credits to process an audio file. Current balance: {credits} credits</p>
                  </div>
                  <a
                    href="#/credits"
                    className="px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition-colors"
                  >
                    Add Credits
                  </a>
                </div>
              </div>
            )}

            <div className="p-4 bg-blue-50 border border-blue-200 rounded-lg text-blue-700 mb-4">
              <p>Please save your audio files and transcripts after processing is completed. Files are not saved on our servers.</p>
            </div>

            <FileUpload 
              onFileChange={handleFileChange}
              files={files}
              onError={setError}
              disabled={credits < 2 || !user.emailVerified}
            />

            <div className="flex justify-center">
              <button
                onClick={handleUpload}
                disabled={isUploading || isProcessing || files.length === 0 || credits < 2 || !user.emailVerified}
                className="flex items-center justify-center px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                {(isUploading || isProcessing) && (
                  <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                )}
                {!isUploading && !isProcessing && <Upload className="w-5 h-5 mr-2" />}
                {isUploading ? 'Uploading...' : isProcessing ? 'Processing...' : 'Process Audio'}
              </button>
            </div>

            {error && (
              <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
                {error}
              </div>
            )}

            {separationResult && files[0] && (
              <AudioSeparationResult
                result={separationResult}
                originalAudio={files[0]}
                isProcessing={isProcessing}
              />
            )}
          </div>
        )}
      </div>
    </AuroraBackground>
  );
};

export default HomePage;