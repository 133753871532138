import React, { useEffect, useRef } from 'react';

interface TextRotateProps {
  primaryText: string;
  rotatingTexts: string[];
  className?: string;
}

const TextRotate: React.FC<TextRotateProps> = ({
  primaryText,
  rotatingTexts,
  className = ""
}) => {
  const rotatingTextRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let currentIndex = 0;
    const element = rotatingTextRef.current;
    if (!element) return;

    const animate = () => {
      element.style.opacity = '0';
      element.style.transform = 'translateY(20px)';
      
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % rotatingTexts.length;
        element.textContent = rotatingTexts[currentIndex];
        element.style.opacity = '1';
        element.style.transform = 'translateY(0)';
      }, 200);
    };

    // Set initial text
    element.textContent = rotatingTexts[0];
    
    const interval = setInterval(animate, 2000);
    return () => clearInterval(interval);
  }, [rotatingTexts]);

  return (
    <h2 className={`text-4xl font-bold text-center ${className}`}>
      {primaryText}{' '}
      <span
        ref={rotatingTextRef}
        className="inline-block bg-indigo-600 text-white px-4 py-2 rounded-lg transition-all duration-200"
        style={{ minWidth: '200px' }}
      />
    </h2>
  );
};

export default TextRotate;