import React from 'react';
import { useLocation, Link } from 'react-router-dom';

interface TabItem {
  name: string;
  path: string;
  external?: boolean;
}

interface AnimatedTabsProps {
  tabs: TabItem[];
}

const AnimatedTabs: React.FC<AnimatedTabsProps> = ({ tabs }) => {
  const location = useLocation();

  return (
    <div className="relative flex space-x-1">
      {tabs.map((tab) => {
        const isActive = location.pathname === tab.path;
        
        // For external links, use anchor tag instead of Link
        if (tab.external) {
          return (
            <a
              key={tab.path}
              href={tab.path}
              target="_blank"
              rel="noopener noreferrer"
              className="relative px-3 py-1.5 text-sm font-medium text-gray-500 hover:text-gray-900 transition-all duration-200 ease-out"
              style={{
                WebkitTapHighlightColor: "transparent",
              }}
            >
              {tab.name}
            </a>
          );
        }

        return (
          <Link
            key={tab.path}
            to={tab.path}
            className={`
              relative px-3 py-1.5 text-sm font-medium transition-all duration-200 ease-out
              ${isActive ? 'text-gray-900' : 'text-gray-500 hover:text-gray-900'}
            `}
            style={{
              WebkitTapHighlightColor: "transparent",
            }}
          >
            {isActive && (
              <span
                className="absolute inset-x-1 -bottom-px h-px bg-gradient-to-r from-indigo-500/0 via-indigo-500/70 to-indigo-500/0"
              />
            )}
            {tab.name}
            {isActive && (
              <span
                className="absolute inset-0 rounded-md bg-indigo-100/50 ring-2 ring-inset ring-indigo-500/20"
                style={{
                  animation: "fade-in 0.2s ease-out",
                }}
              />
            )}
          </Link>
        );
      })}
    </div>
  );
};

export default AnimatedTabs;