import React, { useEffect, useRef } from 'react';

interface TextGenerateEffectProps {
  words: string;
  className?: string;
}

export const TextGenerateEffect: React.FC<TextGenerateEffectProps> = ({
  words,
  className = "",
}) => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const randomNumberBetween = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const animateText = async () => {
      if (!textRef.current) return;
      
      const element = textRef.current;
      const text = words;
      
      // Reset the content
      element.innerHTML = '';
      
      // Create a span for each character
      const characters = text.split('').map(char => {
        const span = document.createElement('span');
        span.textContent = char;
        span.style.opacity = '0';
        return span;
      });
      
      // Add all spans to the element
      characters.forEach(span => element.appendChild(span));
      
      // Animate each character
      for (let i = 0; i < characters.length; i++) {
        const span = characters[i];
        
        // Random delay for more natural effect
        const delay = randomNumberBetween(0, 40);
        await new Promise(resolve => setTimeout(resolve, delay));
        
        span.style.opacity = '1';
      }
    };

    animateText();
  }, [words]);

  return (
    <div ref={textRef} className={className}>
      {words}
    </div>
  );
};

export default TextGenerateEffect;