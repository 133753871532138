import React from 'react';

interface AuroraBackgroundProps {
  children: React.ReactNode;
  className?: string;
}

const AuroraBackground: React.FC<AuroraBackgroundProps> = ({ children, className = "" }) => {
  return (
    <div className={`relative flex flex-col items-center justify-center overflow-hidden bg-white ${className}`}>
      <div className="relative z-10 w-full">
        {children}
      </div>
      <div className="absolute inset-0 w-full h-full">
        {/* Aurora blobs */}
        <div 
          className="absolute top-0 -left-4 w-96 h-96 bg-indigo-300 rounded-full mix-blend-multiply filter blur-[128px] opacity-50 animate-aurora"
          style={{ animationDelay: "0s" }}
        />
        <div 
          className="absolute -top-4 left-1/4 w-96 h-96 bg-blue-300 rounded-full mix-blend-multiply filter blur-[128px] opacity-50 animate-aurora"
          style={{ animationDelay: "2s" }}
        />
        <div 
          className="absolute top-0 right-1/3 w-96 h-96 bg-purple-300 rounded-full mix-blend-multiply filter blur-[128px] opacity-50 animate-aurora"
          style={{ animationDelay: "4s" }}
        />
        <div 
          className="absolute -top-4 right-1/4 w-96 h-96 bg-pink-300 rounded-full mix-blend-multiply filter blur-[128px] opacity-50 animate-aurora"
          style={{ animationDelay: "6s" }}
        />
        <div 
          className="absolute top-1/4 right-0 w-96 h-96 bg-indigo-400 rounded-full mix-blend-multiply filter blur-[128px] opacity-50 animate-aurora"
          style={{ animationDelay: "8s" }}
        />
      </div>
    </div>
  );
};

export default AuroraBackground;