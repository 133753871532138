import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Clock, 
  DollarSign, 
  Brain,
  Languages,
  FileAudio,
  Waves,
  Users,
  Download,
  Sparkles,
  ArrowRight,
  Play,
  Video
} from 'lucide-react';
import TextGenerateEffect from '../components/TextGenerateEffect';
import TextRotate from '../components/TextRotate';
import VideoModal from '../components/VideoModal';
import AuroraBackground from '../components/AuroraBackground';
import TestimonialCard from '../components/TestimonialCard';
import Footer from '../components/Footer';
import GradientBackground from '../components/GradientBackground';

const AboutPage = () => {
  const navigate = useNavigate();
  const tutorialVideoUrl = 'https://youtu.be/He7xVdZfInA';
  const instructionVideoUrl = 'https://firebasestorage.googleapis.com/v0/b/speakersplit-9899f.firebasestorage.app/o/video_assets%2FIntroduction%20video.mp4?alt=media&token=8d803f01-5caa-4715-9d1c-f207158a5606';
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const testimonials = [
    {
      name: "Samiye Reviews",
      title: "AI Video Podcaster",
      testimonial: "SpeakerSplit has revolutionized my podcast editing workflow. The AI-powered speaker separation is incredibly accurate, and the transcription feature saves me hours of work. It's a game-changer for content creators!",
      image: "https://firebasestorage.googleapis.com/v0/b/speakersplit-9899f.firebasestorage.app/o/image_assets%2Fsamiye_reviews_profile.jpg?alt=media&token=4c9bb624-8c65-4ddd-92a4-9c626eaba378"
    },
    {
      name: "Jonathan's AI Hub Jam",
      title: "Content Creator",
      testimonial: "I think your tool has come onto the market at a time that a lot people want to separate speakers. As said in the video it was thanks to a viewer that I found out about it.",
      image: "https://firebasestorage.googleapis.com/v0/b/speakersplit-9899f.firebasestorage.app/o/image_assets%2Fchannels4_profile.jpg?alt=media&token=fa94ff63-d8d8-4d4f-892f-14b880bf4cfa"
    },
    {
      name: "Nathan Peck",
      title: "Non profit founder/content creator",
      testimonial: "This has become a passion project and I appreciate @SpeakerSplit for providing the tools to turn this into a video podcast!",
      image: "https://firebasestorage.googleapis.com/v0/b/speakersplit-9899f.firebasestorage.app/o/image_assets%2F8gLTzTSw_400x400.png?alt=media&token=1f4750c8-f407-4ac7-8a3d-d53a9baa2fa9"
    }
  ];

  const rotatingTexts = [
    'transcribe audio to text',
    'audio to text transcription',
    'voice recording to transcript',
    'transcribe audio file',
    'transcription ai',
    'speech to text transcription',
    'podcast transcription',
    'court transcription',
    'legal transcription',
    'deposition transcription',
    'foreign language transcription',
    'speech separation',
    'diarized transcriptions'
  ];

  const howItWorks = [
    {
      number: '1',
      title: 'Upload Audio File',
      description: 'Click the upload file button, or drag and drop your audio file you would like to process.'
    },
    {
      number: '2',
      title: 'Transcribe and Separate vocals (dialogue/speech)',
      description: 'Click the Process Audio button and SpeakerSplit will automatically transcribe your audio into text and separate the speakers into separate audio tracks for your post-processing needs.'
    },
    {
      number: '3',
      title: 'Download transcription and separated audio files',
      description: 'You can download the diarized transcript in .txt format. Our AI will automatically detect and label different speakers in your recordings. The isolated vocal audio tracks will be available as a .WAV file for each speaker (time-synched).'
    }
  ];

  const benefits = [
    {
      icon: <Clock className="w-12 h-12 text-indigo-500" />,
      title: "Save Time",
      description: "Process audio files in minutes instead of hours of manual editing"
    },
    {
      icon: <DollarSign className="w-12 h-12 text-indigo-500" />,
      title: "Reduce Costs",
      description: "Eliminate expensive audio editing software and professional services"
    },
    {
      icon: <Brain className="w-12 h-12 text-indigo-500" />,
      title: "AI-Powered",
      description: "Advanced machine learning algorithms ensure accurate transcription and audio separation capabilities"
    },
    {
      icon: <Languages className="w-12 h-12 text-indigo-500" />,
      title: "Language Auto-detection",
      description: "Our advanced speech detection supports: English, Spanish, French, German, Italian, Portuguese, Dutch, Hindi, Japanese, Chinese, Finnish, Korean, Polish, Russian, Turkish, Ukrainian, and Vietnamese"
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section - now with responsive padding */}
      <AuroraBackground className="min-h-[56vh]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 md:pt-12 lg:pt-18 pb-12 md:pb-16">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4 md:mb-6">
              Transcribe Audio into Text and Separate vocals from audio tracks
            </h1>
            <TextGenerateEffect
              words="SpeakerSplit uses advanced AI to automatically transcribe audio to text, including seamless support for foreign language transcription, and accurately separates different speakers into separate audio tracks—saving you hours of manual editing."
              className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8 max-w-3xl mx-auto"
            />
            <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
              <button
                onClick={() => navigate('/register')}
                className="inline-flex items-center px-6 md:px-8 py-3 md:py-4 bg-indigo-600 text-white rounded-lg text-lg font-semibold hover:bg-indigo-700 transition-colors"
              >
                Let's Get Started!
                <ArrowRight className="ml-2 w-5 h-5" />
              </button>
              <button
                onClick={() => setIsVideoModalOpen(true)}
                className="inline-flex items-center px-6 md:px-8 py-3 md:py-4 bg-white text-indigo-600 border border-indigo-600 rounded-lg text-lg font-semibold hover:bg-indigo-50 transition-colors"
              >
                <Video className="mr-2 w-5 h-5" />
                Watch Instruction Video
              </button>
            </div>
          </div>
        </div>
      </AuroraBackground>

      {/* How it Works Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          How it Works
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {howItWorks.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center text-white text-2xl font-bold mb-4">
                {item.number}
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">
                {item.title}
              </h3>
              <p className="text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Why Choose SpeakerSplit?
            </h2>
            <TextGenerateEffect
              words="Our powerful AI technology makes transcribing audio to text, and separating dialogue from audio files easier than ever before"
              className="text-xl text-gray-600 max-w-3xl mx-auto"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="text-center p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
              >
                <div className="flex justify-center mb-6">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* What can SpeakerSplit do Section */}
      <div className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <TextRotate
            primaryText="What can SpeakerSplit do?"
            rotatingTexts={rotatingTexts}
            className="mb-16"
          />
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gradient-to-b from-white to-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                {...testimonial}
              />
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <GradientBackground className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="text-white mb-8 md:mb-0">
              <h2 className="text-3xl font-bold mb-4">
                Ready to Transform Your Audio?
              </h2>
              <p className="text-indigo-100 text-lg">
                Join thousands of content creators who trust SpeakerSplit
              </p>
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => navigate('/register')}
                className="px-8 py-4 bg-white text-indigo-600 rounded-lg font-semibold hover:bg-indigo-50 transition-colors"
              >
                Get Started
              </button>
              <a
                href={tutorialVideoUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="px-8 py-4 bg-indigo-500 text-white rounded-lg font-semibold hover:bg-indigo-400 transition-colors flex items-center"
              >
                <Play className="w-5 h-5 mr-2" />
                Watch Tutorial
              </a>
            </div>
          </div>
        </div>
      </GradientBackground>

      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoUrl={instructionVideoUrl}
      />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutPage;