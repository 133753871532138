import React from 'react';
import { Mail } from 'lucide-react';
import AuroraBackground from '../components/AuroraBackground';

const TermsPage = () => {
  return (
    <AuroraBackground className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          Terms of Service for SpeakerSplit.io
        </h1>
        
        <div className="text-sm text-gray-600 mb-8">
          Effective Date: April 1, 2025
        </div>

        <div className="prose prose-indigo max-w-none">
          <p className="text-gray-600 mb-8">
            Welcome to SpeakerSplit.io ("we," "our," or "us"). By accessing or using our website and services, 
            you agree to comply with these Terms of Service. If you do not agree, please do not use our services.
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              1. Acceptance of Terms
            </h2>
            <p className="text-gray-600">
              By accessing and using SpeakerSplit.io, you accept these Terms of Service and our Privacy Policy.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              2. Services Provided
            </h2>
            <p className="text-gray-600">
              SpeakerSplit.io offers AI-powered transcription and speaker separation services. We reserve the right to modify or discontinue services at any time without notice.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              3. User Responsibilities
            </h2>
            <p className="text-gray-600 mb-4">
              Users agree to:
            </p>
            <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
              <li>Provide accurate information when creating an account.</li>
              <li>Use the services lawfully and ethically.</li>
              <li>Not upload or share unlawful, harmful, or infringing content.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              4. Payment and Subscriptions
            </h2>
            <p className="text-gray-600">
              Fees are outlined on our pricing page and are subject to change.<br />
              Payments are non-refundable unless required by law.<br />
              Subscription plans renew automatically unless canceled.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              5. Cookie Policy
            </h2>
            <p className="text-gray-600">
              We use cookies and similar technologies to enhance user experience, track site performance, and analyze traffic. 
              By using SpeakerSplit.io, you consent to our use of cookies in accordance with our Privacy Policy. 
              You can manage cookie preferences through your browser settings.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              6. Intellectual Property
            </h2>
            <p className="text-gray-600">
              All content, trademarks, and services provided by SpeakerSplit.io are protected by intellectual property laws. 
              Users may not copy, modify, or distribute any content without permission.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              7. Limitation of Liability
            </h2>
            <p className="text-gray-600">
              SpeakerSplit.io is not liable for damages arising from the use of our services, including errors, 
              inaccuracies, or interruptions.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              8. Termination
            </h2>
            <p className="text-gray-600">
              We may suspend or terminate accounts for violations of these Terms of Service without prior notice.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              9. Governing Law
            </h2>
            <p className="text-gray-600">
              These terms are governed by the laws of Georgia, United States of America, without regard to conflict of law principles.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              10. Changes to Terms
            </h2>
            <p className="text-gray-600">
              We may update these Terms of Service from time to time. Changes will be posted on this page with a new effective date.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              11. Contact Us
            </h2>
            <p className="text-gray-600 mb-4">
              For questions or concerns, contact us at:
            </p>
            <a 
              href="mailto:support@speakersplit.com"
              className="inline-flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <Mail className="w-5 h-5 mr-2" />
              support@speakersplit.com
            </a>
          </section>

          <div className="mt-12 pt-8 border-t border-gray-200">
            <p className="text-gray-600 text-center">
              Thank you for choosing SpeakerSplit.io!
            </p>
          </div>
        </div>
      </div>
    </AuroraBackground>
  );
};

export default TermsPage;