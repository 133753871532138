import React, { useState } from 'react';
import { Shield, Zap, Sparkles, Loader2, Lock, CreditCard, Clock } from 'lucide-react';
import useAuth from '../hooks/useAuth';
import useStripe from '../hooks/useStripe';
import { CREDIT_PACKAGES } from '../config/credits';
import AuroraBackground from '../components/AuroraBackground';

const CreditsPage = () => {
  const { user } = useAuth();
  const { handleCheckout, isLoading, error: stripeError } = useStripe();
  const [error, setError] = useState('');
  const [processingPriceId, setProcessingPriceId] = useState<string | null>(null);

  const handlePurchase = async (priceId: string, credits: number) => {
    if (!user) {
      setError('Please log in to purchase credits');
      return;
    }

    if (!user.emailVerified) {
      setError('Please verify your email before purchasing credits');
      return;
    }

    try {
      setError('');
      setProcessingPriceId(priceId);
      
      const result = await handleCheckout({ priceId, credits });
      
      if (!result.success) {
        throw new Error(result.error || 'Failed to initiate checkout');
      }
    } catch (err) {
      console.error('Purchase error:', err);
      setError(err instanceof Error ? err.message : 'Failed to initiate checkout');
    } finally {
      setProcessingPriceId(null);
    }
  };

  return (
    <AuroraBackground className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Choose Your Credit Package
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Get credits to process your audio files. Each audio separation uses 2 credits.
          </p>
        </div>

        {(error || stripeError) && (
          <div className="max-w-md mx-auto mb-8 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
            {error || stripeError}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {CREDIT_PACKAGES.map((pkg) => (
            <div
              key={pkg.priceId}
              className={`relative bg-white rounded-2xl shadow-lg overflow-hidden transition-transform hover:scale-105 ${
                pkg.popular ? 'ring-2 ring-indigo-500' : ''
              }`}
            >
              {pkg.popular && (
                <div className="absolute top-0 right-0 bg-indigo-500 text-white px-3 py-1 text-sm font-medium rounded-bl-lg">
                  Popular
                </div>
              )}
              
              <div className="p-6">
                <div className="mb-4">
                  <h3 className="text-2xl font-bold text-gray-900">
                    {pkg.name}
                  </h3>
                  <p className="text-gray-500 text-sm">
                    {pkg.credits} credits
                  </p>
                </div>

                <div className="mb-6">
                  <p className="text-3xl font-bold text-gray-900">
                    ${pkg.price}
                  </p>
                  <p className="text-sm text-gray-500">
                    {pkg.description}
                  </p>
                </div>

                <button
                  onClick={() => handlePurchase(pkg.priceId, pkg.credits)}
                  disabled={isLoading || !user || !user.emailVerified}
                  className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading && processingPriceId === pkg.priceId ? (
                    <>
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    'Purchase Credits'
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Badge Card */}
        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-lg p-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                <Lock className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Secure Payment</h3>
              <p className="text-gray-600 text-sm">
                Your payment information is protected with industry-standard SSL encryption
              </p>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                <Clock className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Instant Processing</h3>
              <p className="text-gray-600 text-sm">
                Credits are added to your account immediately after successful payment
              </p>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                <Shield className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Quality Guarantee</h3>
              <p className="text-gray-600 text-sm">
                Experience high-quality audio separation with our advanced AI technology
              </p>
            </div>
          </div>
        </div>
      </div>
    </AuroraBackground>
  );
};

export default CreditsPage;