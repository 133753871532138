import React from 'react';
import { Link } from 'react-router-dom';
import { Twitter } from 'lucide-react';
import Logo from './Logo';

const Footer = () => {
  const navigation = {
    pages: [
      { name: 'Upload', href: '/' },
      { name: 'About', href: '/about' },
      { name: 'Credits', href: '/credits' },
      { name: 'FAQ', href: '/faq' },
      { name: 'Support', href: '/help' },
    ],
    legal: [
      { name: 'Privacy Policy', href: '/privacy' },
      { name: 'Terms of Service', href: '/terms' },
    ],
  };

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 lg:px-8">
        {/* Top section with logo, name, navigation and social */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center">
            <div className="h-25 w-25">
              <Logo />
            </div>
            <span className="ml-4 text-xl font-semibold text-gray-900">
              SpeakerSplit
            </span>
          </div>

          <div className="flex items-center space-x-8">
            <nav className="flex space-x-6">
              {navigation.pages.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm leading-6 text-gray-600 hover:text-indigo-600 transition-colors"
                >
                  {item.name}
                </Link>
              ))}
            </nav>
            
            <a
              href="https://x.com/SpeakerSplit"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-indigo-500 transition-colors"
            >
              <span className="sr-only">Twitter</span>
              <Twitter className="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
        </div>

        {/* Dividing line */}
        <div className="border-t border-gray-900/10" />

        {/* Bottom section with copyright and legal links */}
        <div className="mt-8 flex items-center justify-between">
          <p className="text-sm leading-5 text-gray-500">
            © 2025 SpeakerSplit.io. All rights reserved.
          </p>
          
          <nav className="flex space-x-6">
            {navigation.legal.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="text-sm leading-6 text-gray-600 hover:text-indigo-600 transition-colors"
              >
                {item.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;