import React, { useCallback } from 'react';
import { FileAudio, X, Lock } from 'lucide-react';

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB in bytes

interface FileUploadProps {
  onFileChange: (files: FileList | null) => void;
  files: File[];
  onError?: (message: string) => void;
  disabled?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileChange, files, onError, disabled }) => {
  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;

    const droppedFiles = e.dataTransfer.files;
    handleFileValidation(droppedFiles);
  }, [disabled]);

  const handleFileValidation = (fileList: FileList) => {
    // Validate file size
    const invalidFiles = Array.from(fileList).filter(file => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      onError?.(`File${invalidFiles.length > 1 ? 's' : ''} ${invalidFiles.map(f => f.name).join(', ')} exceed${invalidFiles.length === 1 ? 's' : ''} the 100MB limit`);
      return;
    }

    onFileChange(fileList);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFileValidation(e.target.files);
    }
  };

  const removeFile = (index: number) => {
    // Create a new array without the removed file
    const updatedFiles = files.filter((_, i) => i !== index);
    
    if (updatedFiles.length === 0) {
      // If no files remain, pass null to clear the input
      onFileChange(null);
      return;
    }

    // Create a new DataTransfer object for the remaining files
    const dataTransfer = new DataTransfer();
    updatedFiles.forEach(file => dataTransfer.items.add(file));
    onFileChange(dataTransfer.files);

    // Clear the file input
    const fileInput = document.getElementById('file-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <div className="space-y-4">
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={`relative ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <input
          type="file"
          className="hidden"
          onChange={handleFileInputChange}
          multiple
          accept="audio/*"
          disabled={disabled}
          id="file-upload"
        />
        <label
          htmlFor="file-upload"
          className={`block w-full ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <div className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors duration-200 
            ${disabled 
              ? 'border-gray-200 bg-gray-50' 
              : 'border-gray-300 hover:border-indigo-500'}`}
          >
            {disabled ? (
              <Lock className="mx-auto h-12 w-12 text-gray-400" />
            ) : (
              <FileAudio className="mx-auto h-12 w-12 text-gray-400" />
            )}
            <div className="mt-4">
              {disabled ? (
                <span className="text-sm text-gray-500">
                  Add credits to upload files
                </span>
              ) : (
                <>
                  <span className="text-sm font-medium text-indigo-600">Click to upload</span>
                  <span className="text-sm text-gray-500"> or drag and drop</span>
                </>
              )}
            </div>
            <p className="text-xs text-gray-500 mt-2">Audio files (MP3, WAV, M4A, FLAC)</p>
            <p className="text-xs text-gray-500 mt-1">Maximum file size: 100MB</p>
          </div>
        </label>
      </div>

      {files.length > 0 && (
        <div className="space-y-2">
          {files.map((file, index) => (
            <div
              key={`${file.name}-${index}`}
              className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm"
            >
              <div className="flex items-center space-x-3">
                <FileAudio className="w-5 h-5 text-indigo-500" />
                <span className="text-sm text-gray-700 truncate max-w-xs">
                  {file.name}
                </span>
                <span className="text-xs text-gray-500">
                  ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                </span>
              </div>
              <button
                type="button"
                onClick={() => removeFile(index)}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                aria-label="Remove file"
              >
                <X className="w-4 h-4 text-gray-500" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;