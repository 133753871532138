import React from 'react';
import { Quote } from 'lucide-react';

interface TestimonialCardProps {
  name: string;
  title: string;
  testimonial: string;
  image: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  title,
  testimonial,
  image
}) => {
  return (
    <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-shadow">
      {/* Quote icon */}
      <div className="absolute -top-4 -left-4 bg-indigo-600 rounded-full p-3">
        <Quote className="w-6 h-6 text-white" />
      </div>

      {/* Content */}
      <div className="pt-4">
        <p className="text-gray-600 mb-6 italic">
          "{testimonial}"
        </p>

        <div className="flex items-center">
          <img
            src={image}
            alt={name}
            className="w-12 h-12 rounded-full object-cover mr-4"
          />
          <div>
            <h4 className="font-semibold text-gray-900">{name}</h4>
            <p className="text-sm text-gray-500">{title}</p>
          </div>
        </div>
      </div>

      {/* Decorative elements */}
      <div className="absolute top-0 right-0 -mt-3 -mr-3 w-24 h-24 bg-indigo-100 rounded-full opacity-20"></div>
      <div className="absolute bottom-0 left-0 -mb-3 -ml-3 w-16 h-16 bg-indigo-200 rounded-full opacity-20"></div>
    </div>
  );
};

export default TestimonialCard;