import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import CreditMonitor from './CreditMonitor';
import UserProfile from './UserProfile';
import AnimatedTabs from './AnimatedTabs';
import useCredits from '../hooks/useCredits';
import useAuth from '../hooks/useAuth';

const Navigation: React.FC = () => {
  const { credits } = useCredits();
  const { user, isLoading } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const publicTabs = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Credits', path: '/credits' },
    { name: 'FAQ', path: '/faq' },
    { name: 'Blog', path: 'https://speakersplit.mystrikingly.com/', external: true },
  ];

  const authTabs = [
    { name: 'Login', path: '/login' },
    { name: 'Register', path: '/register' },
  ];

  const userTabs = [
    { name: 'Help', path: '/help' },
  ];

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center">
            <AnimatedTabs tabs={publicTabs} />
            {!user && !isLoading && <AnimatedTabs tabs={authTabs} />}
            {user && <AnimatedTabs tabs={userTabs} />}
          </div>

          {/* Mobile Navigation Button */}
          <div className="flex md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          
          {/* User Section (Desktop & Mobile) */}
          <div className="flex items-center space-x-4">
            {user && <CreditMonitor credits={credits} />}
            {user && <UserProfile username={user.username} />}
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`${
            isMobileMenuOpen ? 'block' : 'hidden'
          } md:hidden transition-all duration-200 ease-in-out`}
        >
          <div className="pt-2 pb-3 space-y-1">
            {publicTabs.map((tab) => (
              <MobileNavLink
                key={tab.name}
                {...tab}
                onClick={() => setIsMobileMenuOpen(false)}
              />
            ))}
            {!user && !isLoading && authTabs.map((tab) => (
              <MobileNavLink
                key={tab.name}
                {...tab}
                onClick={() => setIsMobileMenuOpen(false)}
              />
            ))}
            {user && userTabs.map((tab) => (
              <MobileNavLink
                key={tab.name}
                {...tab}
                onClick={() => setIsMobileMenuOpen(false)}
              />
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

// Mobile Navigation Link Component
const MobileNavLink: React.FC<{
  name: string;
  path: string;
  external?: boolean;
  onClick: () => void;
}> = ({ name, path, external, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  if (external) {
    return (
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className="block px-3 py-2 text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-50 transition-colors"
        onClick={onClick}
      >
        {name}
      </a>
    );
  }

  return (
    <Link
      to={path}
      className={`block px-3 py-2 text-base font-medium transition-colors ${
        isActive
          ? 'text-indigo-600 bg-indigo-50'
          : 'text-gray-500 hover:text-gray-900 hover:bg-gray-50'
      }`}
      onClick={onClick}
    >
      {name}
    </Link>
  );
};

export default Navigation;