import React from 'react';
import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import AuroraBackground from '../components/AuroraBackground';

const PrivacyPage = () => {
  return (
    <AuroraBackground className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          Privacy Policy for SpeakerSplit.io
        </h1>
        
        <div className="text-sm text-gray-600 mb-8">
          Effective Date: April 1, 2025
        </div>

        <div className="prose prose-indigo max-w-none">
          <p className="text-gray-600 mb-8">
            Welcome to SpeakerSplit.io ("we," "our," or "us"). We are committed to protecting your privacy. 
            This Privacy Policy outlines how we collect, use, and safeguard your information when you use our website and services.
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              1. Information We Collect
            </h2>
            <p className="text-gray-600 mb-4">
              We may collect the following types of information:
            </p>
            <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
              <li>
                <span className="font-medium">Personal Information:</span> Name, email address, payment information, and account details when you register or make purchases.
              </li>
              <li>
                <span className="font-medium">Usage Data:</span> Information on how you interact with our services, including audio files uploaded, transcripts generated, and IP address.
              </li>
              <li>
                <span className="font-medium">Cookies and Tracking Technologies:</span> We use cookies to enhance user experience, track site performance, and personalize content.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              2. How We Use Your Information
            </h2>
            <p className="text-gray-600 mb-4">
              We use collected information to:
            </p>
            <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
              <li>Provide and maintain our services</li>
              <li>Process payments and manage subscriptions</li>
              <li>Improve and optimize our platform</li>
              <li>Communicate with you about updates, offers, and support</li>
              <li>Enforce our Terms of Service and comply with legal obligations</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              3. Data Security
            </h2>
            <p className="text-gray-600">
              We implement industry-standard measures to protect your data. However, no method of transmission over the internet is 100% secure.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              4. Sharing Your Information
            </h2>
            <p className="text-gray-600">
              We do not sell or rent your personal information. We may share data with trusted third-party service providers to facilitate our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              5. Your Rights
            </h2>
            <p className="text-gray-600 mb-4">
              You have the right to:
            </p>
            <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
              <li>Access, correct, or delete your personal information</li>
              <li>Opt out of marketing communications</li>
              <li>Restrict or object to data processing</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              6. Third-Party Links
            </h2>
            <p className="text-gray-600">
              Our site may link to external sites. We are not responsible for the content or privacy practices of these sites.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              7. Changes to This Policy
            </h2>
            <p className="text-gray-600">
              We may update this policy periodically. Changes will be posted on this page with a new effective date.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              8. Contact Us
            </h2>
            <p className="text-gray-600 mb-4">
              If you have questions or concerns, contact us at:
            </p>
            <a 
              href="mailto:support@speakersplit.com"
              className="inline-flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <Mail className="w-5 h-5 mr-2" />
              support@speakersplit.com
            </a>
          </section>

          <div className="mt-12 pt-8 border-t border-gray-200">
            <p className="text-gray-600 text-center">
              Thank you for using SpeakerSplit.io!
            </p>
          </div>
        </div>
      </div>
    </AuroraBackground>
  );
};

export default PrivacyPage;